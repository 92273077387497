<template>
  <div class="src src-detail">
    <header class="src-header">
      <div class="src-logo">
        <img :src="LogoImg" alt="" width="190" />
        <!-- <el-divider direction="vertical"></el-divider>
        <img :src="LogoTxt" alt="" width="92" class="src-logo__txt" /> -->
      </div>
      <ul class="src-nav" v-show="false">
        <li>
          <router-link to="/service-resource-center" class="skew-link skew-link--lr is-active"
            ><span>服务资源中心</span></router-link
          >
        </li>
        <li class="dropdown">
          <a class="skew-link"><span>开发中心</span></a>
          <ul class="dropdown-menu">
            <li>
              <a href="/freedo/#/admin/developmentCenter/HR" target="_blank"> 高渲染开发文档 </a>
            </li>
            <li>
              <a href="/freedo/#/admin/developmentCenter/WebGL" target="_blank"> WebGL开发文档 </a>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="/service-monitor-center" class="skew-link skew-link--rr"
            ><span>服务监控中心</span></router-link
          >
        </li>
      </ul>
      <div class="src-user">
        <span class="src-user__avatar dropdown">
          <i class="el-icon-user user-nav__icon"></i>
          <ul class="dropdown-menu user-center">
            <li>
              <a href="/service-resource-center/user-center/user-info" target="_blank"> 用户信息 </a>
            </li>
            <li>
              <a href="/service-resource-center/user-center/my-token" target="_blank"> 我的Token </a>
            </li>
            <li>
              <a href="/service-resource-center/user-center/service-apply" target="_blank"> 我的申请 </a>
            </li>
          </ul>
        </span>
        <span class="src-user__nick">
          你好，<br />
          <strong>{{ userInfo?.displayName }}</strong>
        </span>
        <a class="skew-link skew-link--r src-user__logout" @click="handleLogout">
          <span><i class="el-icon-switch-button user-nav__icon"></i></span>
        </a>
      </div>
    </header>
    <main class="src-main">
      <space-detail></space-detail>
    </main>
  </div>
</template>
<script>
import LogoImg from '@/assets/img/logo-src.png';
import LogoTxt from '@/assets/img/logo-w-text.png';
import { userInfo } from '@/layout/messageCenter/user-info';
import { computed, reactive, ref } from 'vue';
import { getSpaceServiceList } from '@/api/cim';
import NoImage from '@/assets/img/no-img2.svg';
import SpaceDetail from '@/views/services-center/space/spaceDetail/Index.vue';
import { logout } from '@/api/auth';
export default {
  components: {
    SpaceDetail,
  },
  setup() {
    const listType = ref('grid');
    const collapse = ref(['type', 'tag']);
    const handleChangeListType = (type) => (listType.value = type);
    const query = reactive({
      keyword: '',
      catalogId: null,
      tagNames: [],
      pageNo: 1,
      pageSize: 10,
      sortField: 1,
      sortType: 0,
    });
    const loading = ref(false);
    const list = ref([]);
    const total = ref(0);

    const fetchList = async () => {
      loading.value = true;
      try {
        const { data } = await getSpaceServiceList({
          ...query,
        });
        list.value = data.list;
        total.value = data.total;
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
    };

    // fetchList();

    // 每页条数改变
    const handlePageSizeChange = (pageSize) => {
      query.pageSize = pageSize;
      fetchList();
    };

    // 页数切换
    const handlePageChange = (pageNum) => {
      query.pageNo = pageNum;
      fetchList();
    };

    const handleQueryChange = (key, value) => {
      query[key] = value;
      fetchList();
    };

    const sortInfo = computed(() => {
      const sortType = query.sortType === 1 ? '升序' : '降序';
      const sortField = query.sortField === 1 ? '创建时间' : '更新时间';
      return `${sortField}${sortType}`;
    });

    const handleSort = (field, type) => {
      query.sortField = field;
      query.sortType = type;
      fetchList();
    };

    const handleLogout = () => {
      logout()
        .then(() => {
          window.location.href = '/login';
        })
        .catch(() => {
          ElMessage.error('登出失败');
        });
    };

    return {
      NoImage,
      LogoImg,
      LogoTxt,
      userInfo,
      query,
      loading,
      list,
      total,
      listType,
      collapse,
      sortInfo,
      fetchList,
      handleChangeListType,
      handlePageSizeChange,
      handlePageChange,
      handleQueryChange,
      handleSort,
      handleLogout,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-loading-mask) {
  background: transparent;
}
.src {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #002b52 0%, #101217 80%);
  color: #979da6;
  overflow-x: hidden;
  a {
    color: #237bfb;
  }

  &-header {
    margin: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #237bfb;
    a {
      color: inherit;
    }
  }

  .dropdown {
    position: relative;
    .dropdown-toggle {
      cursor: pointer;
    }
    .dropdown-menu {
      display: none;
      position: absolute;
      width: 100%;
      background: linear-gradient(135deg, #002b52 0%, #101217 80%);
      color: #979da6;
      top: 100%;
      left: -2px;
      margin: 0;
      padding: 0;
      list-style: none;
      border: 1px solid #0059b5;
      z-index: 10;
      box-shadow: 0 0 3px rgba($color: #237bfb, $alpha: 0.7);
      a {
        display: flex;
        padding: 5px 10px;
        height: 40px;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #979da6;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: #237bfb;
          color: white;
        }
      }
    }
    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }
  .skew-link {
    height: 48px;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.1);
    font-size: 16px;
    border: 1px solid #0059b5;
    transform: skewX(-10deg);
    position: relative;
    transition: all 0.2s ease-in-out;
    color: #237bfb;
    > span {
      transform: skewX(10deg);
    }
    &:hover {
      z-index: 1;
      color: white;
    }
    &:focus,
    &:active,
    &.is-active {
      box-shadow: 0 0 10px rgba($color: #237bfb, $alpha: 0.7);
      z-index: 1;
    }
    &.is-active {
      background-color: rgba($color: #0059b5, $alpha: 0.7);
      border-color: #237bfb;
      color: #fff;
      z-index: 1;
    }
    &--lr {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &--rr {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &--r {
      border-radius: 3px;
    }
  }
  &-logo {
    display: inline-flex;
    align-items: center;
    :deep(.el-divider) {
      margin: 0 16px;
    }
    &__txt {
      position: relative;
      top: 1px;
    }
  }
  &-nav {
    display: inline-flex;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
    > li {
      position: relative;
      margin-left: -1px;
      > .skew-link {
        &.is-active {
          background-color: rgba(0, 0, 0, 0.4) !important;
          color: #237bfb;
          font-weight: bold;
        }
      }
    }
  }
  &-user {
    display: inline-flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    .user-nav__icon {
      font-size: 20px;
    }
    &__avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 48px;
      border-radius: 48%;
      background-color: rgba($color: #000000, $alpha: 0.1);
      border: 1px solid transparent;
      font-size: 24px;
      text-align: center;
      i {
        width: 48px;
        height: 48px;
        line-height: 48px;
        border-radius: 48%;
        border: 1px solid #0059b5;
        position: relative;
        left: 40px;
      }
      .dropdown-menu {
        width: 200px !important;
        //margin-top: 10px !important;
      }
    }
    &__nick {
      margin-left: 15px;
      strong {
        font-size: 14px;
        font-weight: bold;
      }
    }
    &__logout {
      margin-left: 30px;
      padding: 10px 12px !important;
      width: 48px;
      height: 48px;
      text-align: center;
    }
  }

  &-banner {
    margin: 20px 40px;
    height: 340px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    &::before {
      height: 100%;
      position: absolute;
      background: rgba(#13294b, 0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: '';
      z-index: 1;
    }
    video {
      width: 100%;
      transform: translateY(-50%);
      position: relative;
      top: 50%;
    }
    &__body {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-wrap {
      width: 100%;
      max-width: 760px;
      background-color: rgba($color: #000000, $alpha: 0.2);
      border: 1px solid #0059b5;
      transform: skewX(-10deg);
      padding: 5px 30px;
      transition: all 0.2s ease-in-out;
      border-radius: 3px;
      font-size: 20px;
      .icon {
        position: absolute;
        transform: skewX(10deg);
        font-size: 24px;
        left: 24px;
        top: 24px;
        color: #237bfb;
      }
      &:focus-within {
        background-color: rgba($color: #000000, $alpha: 0.8);
        border-color: #237bfb;
        font-size: 24px;
        box-shadow: 0 0 10px rgba($color: #237bfb, $alpha: 0.7);
      }
      .search-input {
        height: 60px;
        width: 100%;
        display: block;
        transform: skewX(10deg);
        background-color: transparent;
        border: none;
        outline: none;
        color: #237bfb;
        font-size: inherit;
        font-weight: bold;
        padding: 0 40px;
      }
    }

    .btn-wrap {
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: flex;
      align-items: center;
      .skew-link {
        height: 40px;
        padding: 5px 25px;
        background-color: rgba($color: #0059b5, $alpha: 0.7);
        border-color: #237bfb;
        color: white;
        + .skew-link {
          margin-left: -1px;
        }
      }
    }
  }

  &-main {
    margin: 20px 40px;
    display: flex;
    &__list {
      flex: 1;
      &_filter {
        padding: 10px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .filter-r {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .list-types {
        display: flex;
        align-items: center;
        .skew-link {
          width: 40px;
          height: 40px;
          padding: 2px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          + .skew-link {
            margin-left: -1px;
          }
          span {
            position: relative;
            top: 2px;
            left: 1px;
          }
        }
      }
      :deep(.sa-list-wrap__content) {
        overflow: visible;
      }
      .list-sort {
        margin-left: 10px;
        .skew-link {
          height: 40px;
        }
      }
    }
    &__aside {
      flex-shrink: 0;
      margin-left: 20px;
      width: 400px;
      background-color: rgba($color: #000000, $alpha: 0.2);
      border-radius: 5px;
      :deep(.el-collapse-item__header),
      :deep(.el-collapse-item__wrap),
      :deep(.el-tree) {
        background: transparent;
        color: #5f759b;
      }
      :deep(.el-collapse),
      :deep(.el-collapse-item__wrap),
      :deep(.el-collapse-item__header) {
        border-top-color: #39455d;
        border-bottom-color: #39455d;
      }
      :deep(.el-collapse-item__header),
      :deep(.el-collapse-item__wrap) {
        padding: 0 20px;
        color: #5f759b;
      }
      :deep(.el-collapse-item__content) {
        padding-top: 20px;
      }
      :deep(.el-collapse-item__header) {
        font-size: 14px;
        padding-right: 10px;
        color: #979da6;
        i {
          font-size: 16px;
          margin-right: 5px;
        }
      }
      :deep(.el-tree) {
        .el-tree-node__content:hover {
          background-color: rgba($color: #000000, $alpha: 0.5);
        }
      }
      :deep(.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content) {
        background-color: rgba(0, 89, 181, 0.7);
        color: white;
      }
      :deep(.clear) {
        color: #5f759b;
      }
    }
  }
  :deep(.el-tag) {
    border-radius: 3px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    border: 1px solid #39455d;
    color: #5f759b;
    &.is-active {
      background-color: rgba($color: #0059b5, $alpha: 0.7);
      border-color: #237bfb;
      color: white;
    }
  }

  .list-page {
    margin: 40px 0;
    text-align: center;
    :deep(.el-pagination) {
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;
      .el-icon {
        display: inline-block;
      }
      .btn-next,
      .btn-prev,
      li {
        background-color: rgba($color: #000000, $alpha: 0.1);
        border: 1px solid #0059b5;
        color: #979da6;
      }
      li {
        font-weight: normal;
        &:not(.active):hover {
          color: #237bfb;
        }
        &.active {
          background-color: #237bfb;
          border-color: #237bfb;
        }
      }
    }
  }
}
.src-detail {
  overflow-y: hidden;
}
.dropdown-menu {
  width: 200px !important;
}
</style>
